import React, { Component } from 'react';
import {withStyles} from '@material-ui/core';
import {withTranslation} from 'react-i18next';
import {FusePageSimple, FuseAnimateGroup} from '../../../../@fuse';
import {Icon, Typography, Tooltip, TextField, Card, CardContent, CardHeader, Button, Badge, LinearProgress, Box, InputLabel, FormControl, Select, MenuItem, IconButton} from '@material-ui/core';
import Alert from '../../../components/UI/Alert/Alert';
import ReactTable from "react-table";
import {connect} from 'react-redux';
import * as actions from '../../../store/api/actions';
import moment, { RFC_2822 } from 'moment';
import axios from 'axios';
import {Link} from 'react-router-dom';

const styles = theme => ({
    layoutRoot: {},
    datePicker: {
        // minWidth: '155px',
        width: '100%',
    },
    floatRight: {
        float: 'right',
        marginLeft: '4px',
    },
    cardHeader: {
        backgroundColor: '#f6f8f8',
        borderBottom: '1px solid',
        borderColor: '#dee5e7 !important;'
    },
    successButton: {
        color: '#27c24c !important'
    },
});

class PurchaseOverview extends Component {

    state = {
        fromdate: new Date().toISOString().slice(0, 10),
        todate: new Date().toISOString().slice(0, 10),
        startLoading: false,
        finishedLoading: false,
        percentage: 0,
        AllCustomers: [],
        debnr: '',
        debnaam: '',
        TotalVolume: 0,
        AllOrders: [],
        AllVismaCustomers: [],
        clickedOrdersSuccess: [],
        clickedOrdersError: [],
        ApiResponse: [],
        ApiStatus: 0,
        ApiCreatedId: 0,
    }

    componentDidMount() {
        // this.GetPurchaseOverviewCustomers();
        this.handleDateButtonClick('thismonth');
        
    }
    
    handleFromDate = event => {
        this.setState({fromdate: event.target.value})
    }
    
    handleToDate = event => {
        this.setState({todate: event.target.value})
    }

    handleGenerateClick = () => {
        this.finishedLoading();
        // this.GetPurchaseOverviewDetails();
        this.GetPurchaseOverviewCustomers();
        this.GetPurchaseOverviewDetails();
    }

    startLoading = () => {
        this.setState({finishedLoading: false, startLoading: true, percentage: 0});
    }

    finishedLoading = () => {
        this.setState({finishedLoading: true, startLoading: false});
    }

    applyDates = () => {
        const startDate = moment(this.state.startDate).format('YYYY-MM-DD');
        const endDate = moment(this.state.endDate).format('YYYY-MM-DD');
        this.setState({fromdate: startDate, todate: endDate}, () => this.handleGenerateClick())
    }

    handleDateButtonClick = buttonClicked => {
        let startDate = new Date();
        let endDate = new Date();
        switch(buttonClicked) {
            case 'yesterday':
                startDate.setDate(startDate.getDate() - 1);
                endDate.setDate(endDate.getDate() - 1);
                break;
            case 'thisweek': 
                startDate = this.getMonday(startDate);
                break;
            case 'thismonth': 
                startDate = new Date(startDate.getFullYear(), startDate.getMonth(), 1);
                break;
            case 'thisyear': 
                startDate = new Date(startDate.getFullYear(), 0, 1)
                break;
            case 'lastweek': 
                startDate.setDate(startDate.getDate()-7);
                startDate = this.getMonday(startDate);
                endDate = moment(startDate).add('days', 4).toDate();
                break;
            case 'lastmonth': 
                startDate = new Date(startDate.getFullYear(), startDate.getMonth()-1, 1);
                endDate = new Date(endDate.getFullYear(), endDate.getMonth(), 1);
                endDate.setHours(-4);
                break;
            case 'lastyear': 
                startDate = new Date(startDate.getFullYear() - 1, 0, 1);
                endDate = new Date(endDate.getFullYear() - 1, 11, 31);
                break;
            case 'defaultdate': 
                startDate.setDate(startDate.getDate());
                endDate.setDate(endDate.getDate());
                break;
            default: break;
        }
        this.setState({startDate, endDate}, this.applyDates);
    }

    getMonday = date => {
        let day = date.getDay() || 7;
        if( day !== 1 ) 
            date.setHours(-24 * (day - 1)); 
        return date;
    }

    GetPurchaseOverviewCustomers = () => {
        // this.setState({specialOfferSent: specialOffer});
        axios.get('GetPurchaseOverviewCustomers?fromdate=' + this.state.fromdate + '&todate=' + this.state.todate
          ).then(res => {
            this.setState({AllCustomers: res.data});
            // console.log(res.data);
        })        
    }

    GetPurchaseOverviewDetails = () => {
        if(this.state.debnr){
            axios.get('GetPurchaseOverviewDetails?fromdate=' + this.state.fromdate + '&todate=' + this.state.todate + '&debnr=' + this.state.debnr
            ).then(res => {
                // console.log(res);
                this.setState({AllOrders: res.data});
                this.setState({ApiResponse: "Ok", ApiStatus: res.status, volume: res.data.reduce((accum,item) => accum + item.volume, 0).toFixed(2)});
            }).then(
                this.finishedLoading()
            ).catch((error) => {
                this.setState({ApiResponse: error.response.data, ApiStatus: error.response.status});
                console.log(error.response);
            });
        }
    }

    setDebnr = (DebnrValue, DebnaamValue, FctdatValue) => {
        this.setState({debnr: DebnrValue, debnaam: DebnaamValue, fromdate: FctdatValue, todate: FctdatValue}, () => this.GetPurchaseOverviewDetails(this.state.debnr));
    }

    setFctdatClick = (value) => {
        this.setState({fromdate: value, todate: value}, () => this.GetPurchaseOverviewDetails(this.state.debnr));
    }

    handleKeyDown = (event) => {
        if(event.keyCode === 13) {
            console.log(this.state.debnr);
      }
    }

    render() {
        const {classes, t} = this.props;
        // console.log(this.state.AllOrders);
        
        return (
            <FusePageSimple
                classes={{
                    root: classes.layoutRoot
                }}
                header={
                      <div className="flex flex-1 items-center justify-between p-24">
                         <div className="flex flex-col">
                            <div className="flex items-center mb-16">
                                  <Icon className="text-18" color="action">home</Icon>
                                  <Icon className="text-16" color="action">chevron_right</Icon>
                                  <Typography color="textSecondary">API's</Typography>
                                  <Icon className="text-16" color="action">chevron_right</Icon>
                                  <Typography color="textSecondary">Purchase Overview</Typography>
                            </div>
                            <Typography variant="h6">{this.state.debnaam}</Typography>
                         </div>
                        <div className="flex items-end"><Typography variant="h6">Volume : {this.state.volume}</Typography></div>
                      </div>
                }
                content={
                        <div>
                            <FuseAnimateGroup
                                className="flex flex-wrap"
                                enter={{
                                    animation: "transition.slideUpBigIn"
                                }}
                            >

                            <div className="flex flex-wrap w-full p-12">
                                <div className="flex w-full p-12 md:w-1/4">
                                    <Card className="w-full">
                                        <CardHeader 
                                        className={classes.cardHeader}
                                        title={
                                            <>
                                            <Typography gutterBottom variant="h5" component="h2">
                                                Choose a departure date
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary" component="p">
                                            <b>From</b> {this.state.fromdate} <b>until</b> {this.state.todate} 
                                            {this.state.AllCustomers.length > 0 ? " Rows found : " + this.state.AllCustomers.length : null}
                                            </Typography>
                                            </>
                                        } />
                                        <CardContent>
                                
                                <FormControl className="w-full">
                                <TextField inputProps={{maxLength:6}}
                                        disabled
                                        onChange={(event) => this.setDebnr(event.target.value)} 
                                        value={this.state.debnr}
                                        onKeyDown={this.handleKeyDown} id="debnr" label="Debnr" variant="outlined" />
                                    {/* <span className='error'>Enter debnr</span> */}
                                </FormControl>

                            <div className="flex flex-wrap w-full justify-center p-6" elevation={1}>
                                <Button variant="contained" color="primary" className="m-6" onClick={() => this.handleDateButtonClick('thismonth')}>
                                    {t("THISMT")}
                                </Button>
                                <Button variant="contained" color="primary" className="m-6" onClick={() => this.handleDateButtonClick('thisweek')}>
                                    {t("THISWK")}
                                </Button>
                                <Button variant="contained" color="primary" className="m-6" onClick={() => this.handleDateButtonClick('lastweek')}>
                                    {t("LASTWK")}
                                </Button>
                                <Button variant="contained" color="primary" className="m-6" onClick={() => this.handleDateButtonClick('yesterday')}>
                                    {t("YESTERDAY")}
                                </Button>
                                <Button variant="contained" color="primary" className="m-6" onClick={() => this.handleDateButtonClick('defaultdate')}>
                                    {t("TODAY")}
                                </Button>
                            </div>
                                            <TextField
                                                id="fromdate"
                                                label= {t("STARTDATE") + " " + t("DATE")}
                                                type="date"
                                                value={this.state.fromdate}
                                                // defaultValue={this.state.fromdate}
                                                onChange={this.handleFromDate}
                                                className={classes.datePicker}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }} 
                                            />
                                            <br />
                                            <br />
                                            <TextField
                                                id="todate"
                                                label= {t("ENDDATE") + " " + t("DATE")}
                                                type="date"
                                                value={this.state.todate}
                                                // defaultValue={this.state.todate}
                                                onChange={this.handleToDate}
                                                className={classes.datePicker}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }} 
                                            />
                                            <br />
                                            <br />
                                            <div className="w-full flex flex-wrap">
                                                <div className="w-full xs:w-full sm:w-full md:w-full lg:w-full p-6">
                                                <Button 
                                                    variant="contained" 
                                                    color="primary" 
                                                    fullWidth 
                                                    startIcon={<Icon>autorenew</Icon>}
                                                    onClick={this.handleGenerateClick}>
                                                    {/* <Icon className={"m-10"}>autorenew</Icon>  */}
                                                    {t("PREVIEW")}
                                                </Button>
                                                </div>
                                            </div>
                                            
                                            {this.state.ApiStatus > 350 && this.state.ApiStatus > 0 ? <Alert title={this.state.ApiResponse} status="AlertDanger" className="mt-12" /> : null}

                                        </CardContent>
                                    </Card>

                                </div>
                                <div className="flex w-full p-12 md:w-1/4">                               
                                    <Card className="w-full">
                                            <ReactTable
                                                style={{border: '0px'}}
                                                className="-striped -highlight border-0"
                                                data={this.state.AllCustomers}
                                                columns={[
                                                    {
                                                        Header    : "Debnr",
                                                        accessor  : "debnr",
                                                        filterable: true,
                                                        // Style: "text-align: right;",
                                                        className: "justify-center",
                                                        // width: 100,
                                                        Cell: row =><>                                                            
                                                        <Link to="#" onClick={() => this.setDebnr(row.original.debnr, row.original.name, row.original.fctdat)}>{row.original.debnr}</Link>
                                                            {/* <IconButton aria-label="Edit" onClick={() => this.setDebnr(row.original.debnr)}>
                                                                <Icon>search</Icon>
                                                            </IconButton> */}
                                                        </>
                                                    },
                                                    {
                                                        Header    : "Date",
                                                        accessor  : "fctdat",
                                                        filterable: true,
                                                        // Style: "text-align: right;",
                                                        className: "justify-center",
                                                        // width: 100,
                                                        Cell: row =><>
                                                        {row.original.fctdat}
                                                        {/* <IconButton aria-label="Edit" onClick={() => this.setFctdatClick(row.original.fctdat)}>
                                                            <Icon>search</Icon>
                                                        </IconButton> */}
                                                    </>
                                                    },
                                                ]}
                                                pageSizeOptions={[10, 50, 100]}
                                                pageSize={100}
                                                noDataText="No customers found"
                                            />
                                    </Card>
                                </div>
                                <div className="flex w-full p-12 md:w-1/2">
                                    <Card className="w-full">
                                            <ReactTable
                                                style={{border: '0px'}}
                                                className="-striped -highlight border-0"
                                                data={this.state.AllOrders}
                                                columns={[
                                                    {
                                                        Header    : "Qty",
                                                        accessor  : "levTotaal",
                                                        filterable: true,
                                                        // Style: "text-align: right;",
                                                        className: "justify-center",
                                                        width: 80,
                                                    },
                                                    {
                                                        Header    : "Arttxt",
                                                        accessor  : "arttxt",
                                                        filterable: true,
                                                        // Style: "text-align: right;",
                                                        // className: "justify-center",
                                                        width: 250,
                                                            
                                                    },
                                                    {
                                                        Header    : "Price",
                                                        accessor  : "unitNetPrice",
                                                        filterable: true,
                                                        // Style: "text-align: right;",
                                                        className: "justify-center",
                                                        width: 80,
                                                    },
                                                    {
                                                        Header    : "Total",
                                                        accessor  : "totalNetAmount",
                                                        filterable: true,
                                                        // Style: "text-align: right;",
                                                        className: "justify-center",
                                                        width: 80,
                                                    },
                                                    // {
                                                    //     Header    : "Date",
                                                    //     accessor  : "fctdat",
                                                    //     filterable: true,
                                                    //     // Style: "text-align: right;",
                                                    //     className: "justify-center",
                                                    //     width: 100,
                                                    // },
                                                    {
                                                        Header    : "Volume",
                                                        accessor  : "volume",
                                                        filterable: true,
                                                        // Style: "text-align: right;",
                                                        className: "justify-center",
                                                        width: 80,
                                                    },
                                                ]}
                                                pageSizeOptions={[10, 50, 100]}
                                                pageSize={100}
                                                noDataText="No customers found"
                                            />
                                    </Card>
                                </div>
                            </div>
                                </FuseAnimateGroup>
                            </div>
                }
            />
        )
    }
}

const mapStateToProps = state => {
    return {
        JDFexporterror: state.api.error,
        // loading: state.api.loading,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onGenerateExport: (fromdate, todate) => dispatch(actions.generateJDFexport(fromdate, todate)),
        onDownloadExcel: (fromdate, todate, type) => dispatch(actions.downloadExcelJDFexport(fromdate, todate, type))
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (withStyles(styles, {withTheme: true})(withTranslation()(PurchaseOverview)));